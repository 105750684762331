class NumberStepper extends HTMLElement {
  connectedCallback() {
    this.addEventListener('click', this.onClick)
  }

  onClick = (event) => {
    if (event.target.type === 'button') {
      let amount = Number(event.target.value)

      if (!isNaN(amount)) {
        let $input = this.querySelector('input')
        let value = Number($input.value)

        if (!isNaN(value)) {
          $input.value = String(Math.max(1, value + amount))
        }
      }
    }
  }
}

class DefinitionToggle extends HTMLElement {
  connectedCallback() {
    this.addEventListener('click', this.onClick)
  }

  onClick = (event) => {
    if (event.target.tagName === 'BUTTON') {
      this.toggleAttribute('open')
    }
  }
}

customElements.define('number-stepper', NumberStepper)
customElements.define('definition-toggle', DefinitionToggle)
