class ProductList extends HTMLElement {
  scrolling = false

  connectedCallback() {
    window.addEventListener('touchmove', this.onTouchMove, { passive: true })
    window.addEventListener('touchend', this.onTouchEnd, { passive: true })
    window.addEventListener('touchcancel', this.onTouchEnd, { passive: true })
  }

  disconnectedCallback() {
    window.addEventListener('touchmove', this.onTouchMove)
    window.addEventListener('touchend', this.onTouchEnd)
    window.addEventListener('touchcancel', this.onTouchEnd)
  }

  onTouchMove = (event) => {
    if (!this.scrolling) {
      this.scrolling = true
      this.toggleAttribute('scrolling', true)
    }
  }

  onTouchEnd = (event) => {
    if (this.scrolling) {
      this.scrolling = false
      this.toggleAttribute('scrolling', false)
    }
  }
}

class ProductZoomer extends HTMLElement {
  scaleFactor = 1

  connectedCallback() {
    this.addEventListener('click', this.onClick)
  }

  onClick = (event) => {
    if (!window.matchMedia(`(min-width: ${1280/16}em)`).matches) {
      return
    }

    let enlarge = !this.hasAttribute('zoomed')
    let bounds = this.getBoundingClientRect()

    if (enlarge) {
      this.scaleFactor = (window.innerWidth - /* padding */ 20) / bounds.width
    }

    let scaleFactor = Math.pow(this.scaleFactor, enlarge ? 1 : -1)
    let marginTop = window.scrollY + bounds.top
    let scrollY = window.scrollY + (event.pageY - marginTop) * (scaleFactor - 1)

    this.toggleAttribute('zoomed')
    window.scrollTo({ top: scrollY, behavior: 'instant' })
  }
}

class VariantSelector extends HTMLElement {
  connectedCallback() {
    this.addEventListener('click', this.onClick)
  }

  onClick = (event) => {
    if (event.target.type === 'radio') {
      window.dispatchEvent(
        new CustomEvent('variant:selected', {
          bubbles: true,
          cancelable: true,
          detail: {
            variantName: event.target.dataset.name,
          },
        })
      )
    }
  }
}

class VariantName extends HTMLElement {
  connectedCallback() {
    window.addEventListener('variant:selected', this.onUpdate)
  }

  onUpdate = (event) => {
    this.textContent = event.detail.variantName
  }
}

customElements.define('product-list', ProductList)
customElements.define('product-zoomer', ProductZoomer)
customElements.define('variant-selector', VariantSelector)
customElements.define('variant-name', VariantName)
