let $root = document.documentElement

let tools = {
  'g': 'showGrid',
  'o': 'showOutlines',
}

for (let klass of Object.values(tools)) {
  if (sessionStorage.getItem(klass)) {
    $root.classList.add(klass)
  }
}

window.addEventListener('keydown', event => {
  if (event.ctrlKey) {
    for (let [key, klass] of Object.entries(tools)) {
      if (event.key === key) {
        $root.classList.toggle(klass)
          ? sessionStorage.setItem(klass, 1)
          : sessionStorage.removeItem(klass)
        break;
      }
    }
  }
})
