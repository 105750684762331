class LazyVideo extends HTMLElement {
  connectedCallback() {
    this.video = this.querySelector('video')
    
    if (!this.video) {
      return
    }

    this.promise = Promise.resolve()
    this.observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        this.promise = this.video.play()
          .catch(() => {})
      } else {
        this.promise.then(() => {
          this.video.pause()
        })
      }
    })

    this.observer.observe(this)
  }

  disconnectedCallback() {
    this.observer.disconnect()
  }
}

customElements.define('lazy-video', LazyVideo)
