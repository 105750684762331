import Swup from 'swup'
import SwupA11yPlugin from '@swup/a11y-plugin'
import SwupPreloadPlugin from '@swup/preload-plugin'
import SwupParallelPlugin from '@swup/parallel-plugin'
import SwupBodyClassPlugin from '@swup/body-class-plugin'
import SwupScriptsPlugin from '@swup/scripts-plugin'

import './cart.js'
import './controls.js'
import './debug.js'
import './header.js'
import './product.js'
import './splash.js'
import './video.js'

let swup = window.swup = new Swup({
  plugins: [
    new SwupA11yPlugin(),
    new SwupPreloadPlugin(),
    new SwupParallelPlugin(),
    new SwupBodyClassPlugin(),
    new SwupScriptsPlugin({ optin: true }),
  ],
  containers: ['#root'],
})

swup.hooks.on('page:view', (visit) => {
  let path = visit.to.url
  let { title } = visit.to.document
  let referrer = new URL(visit.from.url, location).toString()
  
  if (window.goatcounter) {
    window.goatcounter.count({ path, title, referrer })
  }
})

// Don't cache visits to the cart, since it updates frequently.
swup.hooks.on('visit:start', (visit) => {
  if (visit.to.url === '/korb') {
    visit.cache.read = false
  }
})
