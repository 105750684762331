async function ensureDialogPolyfilled(element) {
  if (!element.showModal) {
    const { default: dialogPolyfill } = await import('./dialog-polyfill.js')
    dialogPolyfill.registerDialog(element)
  }
}

class HeaderDialog extends HTMLElement {
  connectedCallback() {
    let $dialog = this.querySelector('dialog')
    this.polyfillPromise = $dialog ? ensureDialogPolyfilled($dialog) : Promise.resolve()
    
    // This needs to be `#root` and not `window` because
    // it should only affect the current page, and during page
    // transitions there are two copies of the page in the document.
    this.closest('#root').addEventListener('header-toggle', this.onToggle)
    this.addEventListener('click', this.onClick)
  }

  onToggle = async (event) => {
    await this.polyfillPromise
    
    let $dialog = this.querySelector('dialog')
    if (!$dialog) {
      console.error('No dialog')
      return
    }
    if ($dialog.open) {
      $dialog.close()
    } else {
      $dialog.showModal()
    }
  }

  onClick = async (event) => {
    await this.polyfillPromise
    
    // Close the dialog when navigating to make page transitions visible.
    if (event.target.tagName === 'A') {
      let $dialog = this.querySelector('dialog')
      if (!$dialog) {
        console.error('No dialog')
        return
      }
      $dialog.close()
    }
  }
}

class HeaderToggle extends HTMLElement {
  connectedCallback() {
    this.addEventListener('click', this.onClick)
  }

  onClick = (event) => {
    if (event.target.tagName === 'BUTTON') {
      this.dispatchEvent(
        new CustomEvent('header-toggle', {
          bubbles: true,
          cancelable: true,
        })
      )
    }
  }
}

customElements.define('header-dialog', HeaderDialog)
customElements.define('header-toggle', HeaderToggle)
