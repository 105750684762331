class SplashScreen extends HTMLElement {
  connectedCallback() {
    if (sessionStorage.getItem('hideSplash')) {
      return
    }
    setTimeout(() => {
      this.classList.add('fade')
      this.addEventListener('transitionend', () => {
        sessionStorage.setItem('hideSplash', 1)
        document.documentElement.classList.remove('showSplash')
      })
    }, 1400)
  }
}

customElements.define('splash-screen', SplashScreen)
